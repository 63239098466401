
:root{
  --primary: #8245bf !important;
}
a{
  text-decoration: none !important;
}

.user-select{
    border: 1px solid #f0f1f5 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-radius: 12px !important;
}
select:required:invalid {
    color: gray;
  }
  .css-yk16xz-control{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    padding-left: 10px !important;
    border: 1px solid #f0f1f5 !important;
    border-radius: 12px !important;

}
.css-1pahdxg-control{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    border-color: #2684ff !important;
    border-radius: 12px !important;
    box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }
  .text-font-style{
    font-size: 18px;
    font-weight: 600;
    color: black;
  }

  .react-tel-input{
    width: auto !important;
  }
  .react-tel-input .special-label {
    display: none;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 25px;
    top: -7px;
    background: #fff;
    padding: 0 5px;
    white-space: nowrap;
}
  .react-tel-input .form-control{
    width: 110px !important;
    font-size: 14px !important;
    border-radius: 9px !important;
    border: 1px solid #f0f1f5 !important;
    padding: 18.5px 9px 18.5px 52px !important;
  }
  .react-tel-input .country-list{
    width: 250px !important;
  }
  .login-page-logo {
      font-size: 28px;
      font-weight: 500;
      margin: 0px;
    
      background: linear-gradient(90deg, #8245bf 1.1%, #2ed1c3 100%);
      /* background:#8245Bf , linear-gradient(90deg, #8245BF 1.10%, #2ED1C3 100%); */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }

    /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.btn-primary {
  color: #fff;
  background-color: #8245bf !important;
  border-color: #8245bf !important;
}
.header-right .header-profile > a.nav-link{
  background: #8245bf;
}
[data-headerbg="color_1"] .nav-header .hamburger .line {
  background: #8245bf !important;
}
.deznav .metismenu > li.mm-active > a{
  color: #8245bf !important;

}

[data-headerbg="color_1"] .nav-header .hamburger.is-active .line, [data-headerbg="color_1"] .nav-header .hamburger .line {
  background: #8245bf !important;
}
.text-primary {
  color: #8245bf !important;
}


.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #c395f0 !important;
  border-color: #c395f0 !important;
}
.light.btn-info:hover {
  background-color: #c395f0;
  border-color: #c395f0;
  color: #fff;
}

.pagination .page-item.active .page-link {
  background-color: #8245bf ;
  border-color: #8245bf ;
  color: #fff;
  box-shadow: 0 10px 20px 0px rgba(47, 76, 221, 0.2);
}
.pagination .page-item .page-link:hover{
  background: #8245bf;
}

.header-right .header-profile .dropdown-menu a:hover, .header-right .header-profile .dropdown-menu a:focus, .header-right .header-profile .dropdown-menu a.active {
  color: #8245bf;
}
.login-page-bg div {
  border-radius: 23px;
  background: #fff;
  padding: 10px 22px;
}
.login-h {
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  margin: 0;
}
.login-h {
  background: linear-gradient(90deg,#8245bf 1.1%,#2ed1c3);
  background: var(--hotelly-purple-to-teal,linear-gradient(90deg,#8245bf 1.1%,#2ed1c3));
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 40px;
}

.login-logo-div{
  border-radius: 23px;
  background: #fff;
  padding: 10px 22px;

}
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a:before{
  background: #8245bf;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a{
  background: #8245bf;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a{
  background: #8245bf;
}
.PhoneInputInput {

  border: 0;
}
.table-border{
  border: 1px solid #8245bf;
  padding: 10px;
}
.table-border div{
  border: 1px solid #8245bf;
  padding: 10px;
}
.table-border div span{
color: #428bca;
}
.hotel-owner-assign-data:hover{
  cursor: pointer;
  background-color: #f3f1f1e4;

}

